<template>
    <div class="contacts">
        <div class="small-intro bg-img">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <h1 class="light">Contact us</h1>
                        <div class="metadata">
                            <h1 class="light text-uppercase">
                                <span class="green ">SISSA </span><br>Scuola Internazionale Superiore di Studi Avanzati
                            </h1>
                            <p>The SISSA campus is located in Opicina (Trieste), in Via Bonomea 265. It can easily be reached via public transportation, which connects the campus to downtown Trieste (at 4 km only), or via highway</p>
                        </div>
                        <div class="metadata">
                            <h1 class="light text-uppercase">
                                <span class="green ">ICTP </span><br>International Centre for Theoretical Physics
                            </h1>
                           <p>The ICTP campus is located in Grignano (Trieste), in Strada Costiera 11. It can easily be reached via public transportation, which connects the campus to downtown Trieste (at 8.5 km), or via highway</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container section">
            <div class="row">
                <div class="col-lg-3">
                    <ul>
                        <li>
                            <span><img src="../assets/icon-contacts-place.svg" alt="" /></span><span><span class="green">ICTP</span><br>Strada Costiera 11<br> 34151 Trieste - ITALY</span>
                        </li>
                        <li>
                            <span><img src="../assets/icon-contacts-place.svg" alt="" /></span><span><span class="green">SISSA</span><br>Via Bonomea 265<br> 34136 Trieste - ITALY</span>
                        </li>
                        <li>
                            <span><img src="../assets/icon-contacts-mail.svg" alt="" /></span><span>info@mhpc.it</span>
                        </li>
                        <li>
                            <span><img src="../assets/icon-contacts-phone.svg" alt="" /></span><span>TEL: (+39) 040 3787111</span>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-9 map d-none d-lg-block">
                    <img src="../assets/map-contacts.jpg"  alt="">
                </div>
            </div>
        </div>
        <div class=" section form">
            <div class="container">
                <div class="row">
                <div class="col-lg-6 d-none d-lg-block">
                    <img src="../assets/contacts.jpg" alt="">
                </div>
                <div class="col-lg-6  form-section">
                    <h6 class="light">ANY QUESTION?</h6>
                    <h1 class="light">For more information,<br> <span class="text-uppercase green">Send us a message</span></h1>
                    <form @submit.prevent="checkForm">
                        <div>
                            <div>
                                <input type="text" placeholder="Name" name="name" v-model="name" required/>
                                <label for="">Name</label>
                            </div>
                            <div>
                                <input type="text" placeholder="Email" name="email" v-model="email" required/>
                                <label for="">Email</label>
                            </div>
                            <div>
                                <textarea  type="text" placeholder="Message" name="message" v-model="message" required/>
                                <label for="">Message</label>
                            </div>
                            <div class="checkbox-container">
                                <input type="checkbox" class="form-check-input" id="agree" v-model="agree" required>
                                <label class="form-check-label" for="agree">I agree to the <span>Terms</span> and <span>Privacy Policy</span></label>
                            </div>
                            <div class="button-container">
                                <button class="cta cta-outline">Submit</button>
                            </div>
                            <p class="response">{{ risposta }}</p>
                        </div>    
                    </form>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name:"contacts",
    components: {
    },
    data(){
        return {
            name: '',
            email: '',
            message: '',
            agree: false,
            risposta: ''

        }
    },
    methods:{
        checkForm: function (e) {
            let formdata = new FormData();
            formdata.append('name', this.name);
            formdata.append('email', this.email);
            formdata.append('message', this.message);

            let requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };
            fetch(`${process.env.VUE_APP_ENDPOINT}/contact-form`, requestOptions)
            .then((response) => {
                return response.json();
            })
            .then((json) => {
                 if (json.esito) {
                    this.risposta = 'Messaggio inviato grazie'
                    this.name = this.email = this.message = ''
                } else {
                    this.risposta = 'Qualcosa non va, ricontrolla i campi o contatta l\'amministratore'
                }
            })
            e.preventDefault();
        }
    }
};

</script>

<style lang="scss" scoped>
.metadata{
    margin-bottom: 3rem;
     h1 {
    margin-bottom: 1rem;
}
}
.contacts .section{
    padding-top: 0;
}
.contacts .bg-img {
    background-image: url("../assets/intro-contacts.svg");
    background-size: 50%;
}
.response{
    color: #fff;
    font-weight: 500;
    text-align: center;
}
.small-intro h1 {
    color: #292D34;
}
h1 {
    color: #fff;
    line-height: 2.5rem;
    margin-bottom: 3rem;
    font-size: 1.875rem;
}   
h6 {
    margin-bottom: 0.5rem;
}
form {
    div {
        div{
            flex-direction: column-reverse;
            display: flex;
            margin-bottom: 3rem;
            label {
                font-size: 1rem;
                color: #1DC07E !important;
            }
            textarea,
            input {
                background-color: transparent;
                color: #1DC07E;
                padding: 10px 10px;
                border: 0px;
                border-bottom: 1px solid #1DC07E;
                &:focus{
                    outline: transparent;
                    border-bottom: 1px solid #fff;
                    transition: 0.3s;
                    color: #fff;
                }
                &:focus + label {
                    color: #fff;
                    transition: 0.3s;
                }
            }
            input:focus + label,
            input[type='checkbox'] + label{
                label{
                    color:#1DC07E;
                }   
            }
            
            textarea {
                min-height:100px;
            }
            
        }
    }
}
.checkbox-container {
    display: flex;
    flex-direction: row;
    input {
        position: relative;
        margin: 0;
        top: 6px;
    }
    label{
        margin-left: 11px;
        span{
            color: #fff;
        }
    }
}
.button-container {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
}
button.cta-outline {
    color: #fff;
    font-weight: 600;
    padding: 6px 48px;
    display: inline-block;
}
.form {
    background: #293446;
}
.form-section {
    padding: 3rem 0rem 0rem 6rem;
}

ul {
    max-width: 500px;
    padding: 1rem 0rem 0rem;
    &:before{
    content: "";
    width: 100px;
    height: 2px;
    background-color: #1DC07E;
    display: block;
    top: 0px;
    left: 0;
    position: absolute;
    }
    position: relative;
    li {
        padding: 1rem 0rem;
        font-size: 1.25rem;
        font-weight: 300;
        list-style: none;
        display: flex;
        span:first-child{
            min-width: 39px;
        }
        img{
            margin-right:1rem ;
        }
    }
}

@media(max-width:992px){
    .form-section {
        padding: 0rem 6rem 0rem 6rem ;
    }
    h1 br {
        display: none;
    }
}
@media(max-width:768px){
    .form-section {
        padding: 0rem ;
    }
    h1 br {
        display: none;
    }
    .small-intro > div div {
        margin-bottom: 0;
    }
    ul {
        padding: 1rem 0rem 0rem;
        margin: 0;
        
        li{
            padding: 0.5rem 0rem;
        }
    }
}
</style>